import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";
import axios from "axios";
// import { Link } from "react-router-dom";
const Quote = () => {
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "",
    address: "",
    company_name: "",
    number_of_drawings: 0,
    target_number_of_days: 0,
    specification_files: null,
  });
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        "https://qto.solutions/reactapp/create-quote/",
        formData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 201) {
        // Successful response, you can handle success here
        console.log("Form submitted successfully");
      } else {
        // Handle errors here
        console.error("Form submission failed");
      }
    } catch (error) {
      // Handle network errors here
      console.error("Network error:", error);
    }
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  return (
    <>
      <div className="login-p">
        {/* <img className="pdf" alt="" src="./images/register-background.png" /> */}
        <div>
          <div className="register-form-quote">
            <h2 className="pageheading">Get Free Quote</h2>
            <div className="container">
              <Form className="form-inline" onSubmit={handleSubmit}>
                <div className="row">
                  <Form.Group
                    controlId="formBasicName"
                    className="form-group-quote"
                  >
                    {/* <Form.Label>Name</Form.Label> */}
                    <img className="user" alt="" src="./images/user.png" />
                    <Form.Control
                      className="from-placeholder"
                      type="text"
                      placeholder="First Name"
                      name="first_name"
                      value={formData.first_name}
                      onChange={handleChange}
                    />
                  </Form.Group>

                  <Form.Group
                    controlId="formBasicName"
                    className="form-group-quote "
                  >
                    <img className="user" alt="" src="./images/user.png" />
                    <Form.Control
                      className="from-placeholder"
                      type="text"
                      placeholder="Last Name"
                      name="last_name"
                      value={formData.last_name}
                      onChange={handleChange}
                    />
                  </Form.Group>

                  <Form.Group
                    controlId="formBasicName"
                    className="form-group-quote"
                  >
                    <img
                      className="user"
                      alt=""
                      src="./images/mail-black.png"
                    />
                    <Form.Control
                      className="from-placeholder"
                      type="text"
                      placeholder="Email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                    />
                  </Form.Group>

                  <Form.Group
                    controlId="formBasicPassword"
                    className="form-group-quote"
                  >
                    <img className="user" alt="" src="./images/phone.png" />
                    <Form.Control
                      type="text"
                      placeholder="Phone Number"
                      className="from-placeholder"
                      name="phone_number"
                      value={formData.phone_number}
                      onChange={handleChange}
                    />
                  </Form.Group>

                  <Form.Group
                    controlId="formBasicPassword"
                    className="form-group-quote"
                  >
                    <img className="user" alt="" src="./images/address.png" />
                    <Form.Control
                      type="text"
                      placeholder="Address"
                      className="from-placeholder"
                      name="address"
                      value={formData.address}
                      onChange={handleChange}
                    />
                  </Form.Group>
                  <Form.Group
                    controlId="formBasicPassword"
                    className="form-group-quote"
                  >
                    <img className="user" alt="" src="./images/Company.png" />
                    <Form.Control
                      type="text"
                      placeholder="Company Name"
                      className="from-placeholder"
                      name="company_name"
                      value={formData.company_name}
                      onChange={handleChange}
                    />
                  </Form.Group>
                  <Form.Group
                    controlId="formBasicPassword"
                    className="form-group-quote"
                  >
                    <img className="user" alt="" src="./images/NOF.png" />
                    <Form.Control
                      type="text"
                      placeholder="No Of Drawings"
                      className="from-placeholder"
                      name="number_of_drawings"
                      value={formData.number_of_drawings}
                      onChange={handleChange}
                    />
                  </Form.Group>

                  <Form.Group
                    controlId="formBasicPassword"
                    className="form-group-quote"
                  >
                    <img className="user" alt="" src="./images/NOF.png" />
                    <Form.Control
                      type="text"
                      placeholder="Target No Of Days"
                      className="from-placeholder"
                      name="target_number_of_days"
                      value={formData.target_number_of_days}
                      onChange={handleChange}
                    />
                  </Form.Group>
                  <Form.Group
                    controlId="formBasicPassword"
                    className="form-group-quote-file "
                  >
                    <img
                      className="upload-img"
                      alt=""
                      src="./images/upload.png"
                    />
                    <Form.Control
                      type=""
                      placeholder="Drag your Specification Files Here"
                      className="from-placeholder"
                      name="specification_files"
                      value={formData.specification_files}
                      onChange={handleChange}
                    />
                    <span>only pdf fiels ar allowed</span>
                  </Form.Group>

                  <Button
                    variant="primary"
                    type="submit"
                    className="regist-but-quote"
                  >
                    Get Free Quote
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Quote;
