import React from "react";

function Career_Detail() {
  return (
    <>
     <h1>asdfasfasdf</h1>
    </>
  );
}

export default Career_Detail;
